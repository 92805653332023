@use "/src/styles/index.scss" as theme;

.planCoverages {

  @include theme.shadow;
  @extend %bg-color-white;
  border-radius: 12px;
  padding: 15px;
  margin: 30px;

  &__header {
    @include theme.flexbox-content-between;
    height: 70px;
    margin: 0 28px;

    &__vehicle {
      @include theme.flexbox-content-vertical-center;

      img {
        width: 60px;
        height: 60px;
        object-fit: contain;
      }

      &__details {
        text-transform: uppercase;
        margin-left: 15px;
      }

			@include theme.respond-to(map-get(theme.$breakpoints, 'screen-mobile')) {

			}
    }

    &__toggle {

      @extend %bg-color-light-grey;
      border-radius: 50%;
      width: 45px;
      height: 45px;
      transition: 150ms;
      align-self: center;

      &[is-expanded] {
        transform: rotate(180deg);
      }

      svg {
        width: 35px;
        height: 35px;
      }
    }

    @include theme.respond-to(map-get(theme.$breakpoints, 'screen-medium')) {
      margin: 0;
      height: 80px;

      &__vehicle {
				@include theme.flexbox-column;
				@include theme.align-items(flex-start);
				
        img {
          display: none;
        }

        &__details {
          margin-left: 0;
          max-width: 100%;
					@include theme.font-size(2);
        }
      }

      &__toggle {
        width: 35px;
        height: 35px;

        svg {
          width: 25px;
          height: 25px;
        }
      }
    }

		// @include theme.respond-to(map-get(theme.$breakpoints, 'screen-mobile')) {
		// 	&__vehicle {
		// 		&__details {
		// 			max-width: none;
		// 		}
		// 	}
		// }
  }

  &__content {

    &--border {
      border-top-width: 2px;
      border-top-style: solid;
      @extend %border-color-light-grey;
      margin-top: 6px;
    }

    &__item {
      @include theme.flexbox;
      margin: 28px;
      padding: 0 15px;

      &__image {
        align-items: baseline;

        div {

          background-color: darken(#fff, 4%);
          border-radius: 50%;
          width: 80px;
          height: 80px;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }

      &__values {

        width: 100%;
        margin-left: 30px;

        &__name {
          
          @include theme.flexbox-content-between;

          &__toggle {
            @include theme.flexbox-content-vertical-center;
            @include theme.justify-content(flex-end);
            width: 100px;
          }
        }


        &__list {
          margin-top: 15px;

          &__item {
            @include theme.flexbox;
            margin-bottom: 15px;

            span {
              align-items: baseline;
              svg {
                @extend %text-color-main;
                width: 25px;
                height: 25px;
              }
            }

            &__value {
              margin-left: 8px;
            }
          }
        }

        &__customization {

          max-width: 320px;
        }
      }

      @include theme.respond-to(map-get(theme.$breakpoints, 'screen-medium')) {

        margin: 30px 0;
        padding: 0;

        &__image {
          display: none;
        }

        &__values {
          margin-left: 0;

          &__name {

            &__toggle {
              width: 100px;
            }
          }
        }

        &__list {
        
          &__item {
          
            span {
              svg {
                width: 20px;
                height: 20px;
              }
            }
          }
        }
        
      }      
    }

    *[element-disabled] {

      opacity: .4;
      pointer-events: none;
    }
  }

  @include theme.respond-to(map-get(theme.$breakpoints, 'screen-medium')) {

    margin: 15px;
  }
}