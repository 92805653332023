/* Functional Component styles */
@use '/src/styles/index' as theme;  

.stepsBarWrapper {
	background-color: #ececec;
	height: 10px;
	border-radius: 5px;
	width: inherit;
	position: relative;

	&__bar {
		@extend %bg-color-main;
		height: 10px;
		border-radius: 5px;
		
		&.red {
			background-color: var(--color-red);
		}
		
	}
}