/* Functional Component styles */
@use '/src/styles/index' as theme;

.checkboxWrapper {
  margin: 5px 0;
  font-size: .9rem;
  font-weight: 500;
	text-align: left;
	position: relative;
	@include theme.flexbox-content-center;
	
  &__checkText {
    margin-left: 35px;

    a {
      position: relative;
      z-index: 10;
      color: #3d76e0;

      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }

  input {
    opacity: 0;
    top: 0;
    left: 0;
    bottom: 0;
    cursor: pointer;
    z-index: 1;
    margin: auto;
		position: absolute;
		height: 100%;
		width: 22px;
  }

  &__checkMark {
		position: absolute;
		display: inline-block;
		vertical-align: middle;
    top: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    height: 20px;
    width: 20px;
    background-color: #fff;
    border-radius: 5px;
    border-style: solid;
    border-width: 2px;
    border-color: #afb6c0;

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 3px;
      right: 0;
      margin: auto;
      z-index: 1;
      display: none;
      pointer-events: none;
      width: 4px;
      height: 10px;
      border: solid white;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }

  input:checked ~ .checkboxWrapper__checkMark {
    background-color: #31D16E;
    border-color: #31D16E;
  }

  input:checked ~ .checkboxWrapper__checkMark:after {
    display: block;
  }

  &[position-end] {
    justify-content: flex-end;

    .checkboxWrapper__checkText {
      margin-right: 30px;
      margin-left: 0px;
			display: inline-block;
			vertical-align: middle;
    }
    
    .checkboxWrapper__checkMark {
      left: auto;
      right: 0;
    }
  }
}