/* Functional Component styles */
@use '/src/styles/index' as theme;

.inputWrapper {
	@include theme.input-border;
	@include theme.input-radius;
  transition: 150ms;
	position: relative;

	&--noBorder {
		border: none;
	}

	&__label {
		@include theme.input-label-color;
		position: absolute;
		transform: translate(15px, 17px) scale(1);
		transform-origin: top left;
		transition: transform 150ms;
		cursor: text;
		pointer-events: none;

		&--active {
			transform: translate(15px, 4px) scale(.75);
		}
	}

	&:focus-within {
		@include theme.input-focus-border-color; 
		transition: 200ms border-color;
	}

	&--error {
		@include theme.input-border-error;

		&:focus-within {
			@include theme.input-border-error;
			transition: 200ms border-color;
		}
	}

 	&__input {
		@include theme.input-padding(20px, 20px, 10px, 15px);
		@include theme.input-radius;
		@include theme.input-color;
		@include theme.input-width-available;
		@include theme.font-size(-2);
		@include theme.input-caret-color;
		border: none;
		outline: 0;
		z-index: 1;

		&:read-only {
			cursor: pointer;
		}

		&:disabled {
			cursor: not-allowed;
		}

		&--uppercase {
			text-transform: uppercase;
		}

		&--password {
			@include theme.input-radius;
			@include theme.input-padding(20px, 40px, 10px, 15px);
			border: none;
		}
  }

	&__eye-button {
		@extend %text-color-grey;
		position: absolute;
    right: 5px;
    height: 100%;
    width: 40px;
    border: none;
    background-color: transparent;
    font-size: 1.4rem;
    user-select: none;

    &[is-visible] {
      @extend %text-color-main;
    }
    
  }

	&__icon {
		@include theme.flexbox-column-content-center;
		@extend %text-color-grey;
		position: absolute;
		top: 0;
    right: 0;
    height: 100%;
    width: 40px;
    border: none;
    background-color: transparent;
    font-size: 1.4rem;
    user-select: none;

    &[is-visible] {
      @extend %text-color-main;
  
		}
	}
			
	&__loader {
		@extend %text-color-light-grey;

		svg {
			right: 20px;
			width: 35px;
			height: 35px;
			left: auto;
		}
	}
	
}