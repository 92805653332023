@use '/src/styles/index' as theme;

.contractingButton{

  position: fixed;
  z-index: 99999;
  left: 0;
  right: 0;
  margin: auto;
  background: #ffffff8f;
  padding: 2rem;
  backdrop-filter: blur(6px);
  bottom: 0;
  display: none;
  @include theme.shadow;
  animation: 200ms ease buttonPopUp;
  -webkit-animation: 200ms ease buttonPopUp;

  &--visible {
    display: block;
  }

  @keyframes buttonPopUp {
    0% { 
      opacity: 0; 
      transform: translateY(25%); 
    }
    100% { 
      opacity: 1;
      transform: translateY(0); 
    }     
  }

  &__button {
    box-sizing: border-box;
    max-width: 320px;
    text-transform: uppercase;
    font-size: .8rem;
    display: block;
    width: 100%;
    margin: auto;
    height: 46px;

    &__normal {
      font-weight: 400;
    }

    &--large {
      max-width: 340px;
    }
  }

}