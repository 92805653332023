@each $key, $val in $colors {

  /* Text */ 
  // Output example: %text-color-main
  %text-color-#{$key} {
    color: $val;
  }


  /* Text decoration line */
  // Output example: %text-line-color-main
  %text-underline-color-#{$key} {
    text-decoration: underline;
    text-decoration-color: $val;
  }


  /* Background */
  // Output example: %bg-color-main
  %bg-color-#{$key} {
    background-color: $val;
  }

  // Output example: %bg-hover-color-main
  %bg-hover-color-#{$key} {
    background-color: lighten($val, 10);
  }


  /* Border */
  // Output example: %border-color-main
  %border-color-#{$key} {
    border-color: $val;
  }

  // Output example: %border-left-color-main
  %border-left-color-#{$key} {
    border-left-color: $val;
  }

  // Output example: %border-top-color-main
  %border-top-color-#{$key} {
    border-top-color: $val;
  }

  // Output example: %border-right-color-main
  %border-right-color-#{$key} {
    border-right-color: $val;
  }

  // Output example: %border-bottom-color-main
  %border-bottom-color-#{$key} {
    border-bottom-color: $val;
  }
}