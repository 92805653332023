/* Functional Component styles */
@use '/src/styles/index' as theme;  

.quoterVehicle {

	height: 100%;

	&__vehicleManagement {
		padding: 20px 40px;
		min-width: 400px;
		box-shadow: 0 0 10px -6px;
		border-radius: 12px;
	
		@include theme.respond-to(map-get(theme.$breakpoints, 'screen-mobile')) {
			padding: 20px 25px;
			border-radius: 15px;
			border-bottom-right-radius: 0px;
			border-bottom-left-radius: 0px;
			position: fixed;
			bottom: 0;
			width: stretch;
			min-width: unset;
		}
	}
}