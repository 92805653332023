@use '/src/styles/index.scss' as theme;

.btn {

  padding: 7px 20px;
  font-weight: 600;
  border-radius: 8px;
  transition: 200ms;
  min-width: 150px;
  border-width: 2px;
  border-style: solid;
  position: relative;
  @include theme.font-size(-3);

  &--hide {
    pointer-events: none;
    
    .btn__content {
      visibility: hidden;
    }
  }

  &[disabled] {
    pointer-events: none;
    opacity: .4;
  }

  &--fill-filled {
    $boxShadowValues: 0 10px 20px; 
    border-color: transparent;
    @extend %text-color-white;

    &--color-main {
      @extend .btn--fill-filled;
      @extend %bg-color-main;
      box-shadow: $boxShadowValues rgba(map-get(theme.$colors, 'main'), .25);
      &:hover {
        @extend %bg-hover-color-main;
      }
    }

    &--color-red {
      @extend .btn--fill-filled;
      @extend %bg-color-red;
      box-shadow: $boxShadowValues rgba(map-get(theme.$colors, 'red'), .25);
      &:hover {
        @extend %bg-hover-color-red;
      }
    }

    &--color-green {
      @extend .btn--fill-filled;
      @extend %bg-color-green;
      box-shadow: $boxShadowValues rgba(map-get(theme.$colors, 'green'), .25);
      &:hover {
        @extend %bg-hover-color-green;
      }
    }
  
  }

  &--fill-outline {
    @extend %bg-color-white;

    &:hover {
      @extend %text-color-white;
    }

    &--color-main {
      @extend %text-color-main;
      @extend %border-color-main;
      &:hover {
        @extend %bg-color-main;
      }
    }

    &--color-red {
      @extend %text-color-red;
      @extend %border-color-red;
      &:hover {
        @extend %bg-color-red;
      }
    }

    &--color-green {
      @extend %text-color-green;
      @extend %border-color-green;
      &:hover {
        @extend %bg-color-green;
      }
    }
  
  }

  &--fill-none {
    border-width: 0;
    border-radius: 0;
    min-width: 100px;
    @extend %bg-color-white;

    &--color-main {
      @extend %text-color-main;
      &:hover {
        @extend %text-underline-color-main;
      }
    }

    &--color-red {
      @extend %text-color-red;
      &:hover {
        @extend %text-underline-color-red;
      }
    }

    &--color-green {
      @extend %text-color-green;
      &:hover {
        @extend %text-underline-color-green;
      }
    }
  }

  &__content {

    @include theme.flexbox-content-center;

    &__icon  {

      margin-bottom: 1px;

      &--left {
        margin-right: 7.5px;
      }

      &--right {
        margin-left: 7.5px;
      }

      svg {
        
        width: 20px;
        height: 20px;
      }
    }
  }
}