/* Functional Component styles */
@use '/src/styles/index' as theme;  

.quoter {
  @include theme.flexbox;
  @include theme.flex-direction(column);
	$easeOutQuint: cubic-bezier(0.23, 1, 0.32, 1);

	height: 100%;

	&__routes {
		flex: 1;
		margin-top: 130px;
	}

}