/* Functional Component styles */
@use '/src/styles/index' as theme;

.planReviewCard {
	@include theme.shadow;
	width: stretch;
	padding: 20px;
	border-radius: 10px;

	&:last-of-type {
		margin-bottom: 30px;
	}

	&__title {
		@include theme.flexbox-content-vertical-center;
		cursor: pointer;

		&__image {
			width: 60px;
			height: 60px;
			background-position: center;
			background-size: contain;
			background-repeat: no-repeat;
			margin: 0 30px 0 0;
		}

		&__button {
			@extend %bg-color-light-grey;
			@include theme.font-size(5);
			transition: 250ms;
			margin: 0 0 0 auto;
			border-radius: 50%;
			width: 40px;
			height: 40px;

			&--rotated {
				transform: rotate(180deg);
			}
		}
	}

	&__content {

		&__wrapper {
			@extend %border-top-color-light-grey;
			border-top-width: 2px;
			border-top-style: solid;
			margin-top: 15px;
			padding: 30px 0 20px;
			display: grid;
			gap: 40px;
			grid-template-columns: repeat(3, 1fr);

			&--twoItems {
				display: grid;
				//place-items: center;
				grid-template-columns: 1fr 1fr;
			}

			&__coverages {
				@include theme.flexbox-column-content-center;
				padding-bottom: 0;
			}
		}
	}

	
	@include theme.respond-to(1200px) { 
		
		&__content {

			&__wrapper {
				grid-template-columns: repeat(2, 1fr);
			}
		}
	}

	@include theme.respond-to(map-get(theme.$breakpoints, 'screen-mobile')) {

		&__content {

			&__wrapper {
				padding: 10px 0 0;
				gap: 20px;
			}
		}
	}
}