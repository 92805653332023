@mixin font-size($diff: 0px) {
  @each $breakpoint, $size in $font-sizes {
    @if $breakpoint == null {
      font-size: $size + $diff;
    }
    @else {
      @media screen and (min-width: $breakpoint) {
        font-size: $size + $diff;
      }
    }
  }
}