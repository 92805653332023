/* Functional Component styles */
@use '/src/styles/index' as theme;

.quoterContent {

  @include theme.flexbox-column;
  height: 100%;

  .backButton {
    position: absolute;
		left: 100px;
    top: 0;
    z-index: 1;

		@include theme.respond-to(1000px) {
      left: 40px;
    }

    @include theme.respond-to(map-get(theme.$breakpoints, 'screen-medium')) {
      left: 10px;
      top: -35px;
    }
  }

  &__section {

    &--header {
      @include theme.flexbox-column-vertical-center;
      @include theme.flex(.2);
      position: relative;

      @include theme.respond-to(map-get(theme.$breakpoints, 'screen-medium')) {
        margin-top: 10px;
      }
    }

    &--content {
      @include theme.flexbox-column-horizontal-center;
      @include theme.flex(1);
      margin-top: 40px;

      .quoterContent__section__transitioner{
        @include theme.respond-to(map-get(theme.$breakpoints, 'screen-mobile')) {
          width: 100%;
          height: 100%;
          position: relative;
        }
      }

      @include theme.respond-to(map-get(theme.$breakpoints, 'screen-medium')) {
        margin-top: 0;
      }
    }

    &__title {
      @include theme.respond-to(map-get(theme.$breakpoints, 'screen-medium')) {
        padding: 0 60px;
      }      
    }

    &__subtitle {
      max-width: 700px;
      margin: auto;
      padding: 0 20px;

      @include theme.respond-to(map-get(theme.$breakpoints, 'screen-medium')) {
        padding: 0 30px;
      }
    }

    &__message {
      margin-top: 20px;
			padding: 0 20px;
			max-width: 700px;
			margin: 20px auto 0;
    }

    &__transitioner {
      animation-name: section-in;
      animation-duration: 600ms;
      animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
      animation-fill-mode: forwards;

      &--delay {
        animation-delay: .2s;
        opacity: 0;
      }
    }

    @keyframes section-in {

      0% {
        opacity: .1;
        transform: translateY(25%);
      }

      100% {
        opacity: 1;
        transform: translateY(0);
      }
    }

    &__helper--mobile {
      display: none;
      @include theme.respond-to(map-get(theme.$breakpoints, 'screen-medium')) {
        display: flex;
        img {
          width: 75px;
          height: 75px;
          border-width: 5px;
          border-radius: 50%;
          margin: auto;
          border: 8px solid #fff;
          margin-bottom: 15px;
          @include theme.shadow;
        }

      }
    }
  }
}