/* Functional Component styles */
@use '/src/styles/index' as theme;  

.quoterPayment {
	height: 100%;

	&__dataWrapper {
		@include theme.flexbox;
		@include theme.align-items(flex-start);

		@include theme.respond-to(map-get(theme.$breakpoints, 'screen-medium')) {
			justify-content: center;
		}

		@include theme.respond-to(map-get(theme.$breakpoints, 'screen-mobile')) {
			padding-bottom: 250px;
		}

		&__inputsSection {
			@include theme.flexbox-column-content-center;
			max-width: 400px;
			min-height: 100px;
			gap: 20px;

			.dropdownWrapper, .inputWrapper, .inputCardWrapper, form {
				width: stretch;
			}
			
			&__information {
				@include theme.shadow;
				text-align: center;
				margin: 20px 0 0;
				padding: 10px 15px 20px;
				border-radius: 10px;

				&__title {
					margin: 15px 0;
				}
			}
		}

		&__coverText {
			margin: 20px;
			display: block;
		}

		&__mobileCoverText {
			display: none;
			margin: 20px 15px 300px;
		}

		@include theme.respond-to(map-get(theme.$breakpoints, 'screen-medium')) {
			&__mobileCoverText {
				display: block;
			}
		}
	}

	&__dataWrapper__infoSection, &__mobileInfoSection {
		@include theme.shadow;
		@extend %bg-color-white;
		max-width: 300px;
		padding: 0 15px;
		border-radius: 10px;
		text-align: center;
		margin-bottom: 20px;
    margin-left: 80px;

		&__amounts {
			padding: 10px 0;
			border-bottom-width: 1px;
			border-bottom-style: solid;
			@extend %border-color-light-grey;

			&__bigAmount {
				@include theme.font-size(16);
				letter-spacing: -2px;
				line-height: 1;
			}

			&__subscriptionAmount {
				margin-top: 10px;
				letter-spacing: -2px;
				line-height: 1;
			}

			&__monthly {

				&__price {
					line-height: 1;
					margin: 5px 0 0;
				}
			}

			&__button {
				margin: 15px auto 10px;

				svg { 
					@include theme.font-size(7);
					@extend %text-color-main;
					margin: -3px 0 0 3px;
					transition: all 100ms;
				}

				&[is-expanded] {

					svg {
						transform: rotate(180deg);
					}
				}
			}
		}

		&__summary {
			border-bottom-width: 1px;
			border-bottom-style: solid;
			@extend %border-color-light-grey;
			
			&__title {
				display: block;
				margin: 15px 0 10px;
			}

			&__list {
				margin-bottom: 10px;

				&__listItem {
					@include theme.flexbox;
					@include theme.justify-content(space-between);
					margin: 5px 0;
				}

				&__monthlyItem {
					text-align: left;
					
					&__amount {
						width: stretch;
						@include theme.flexbox;
						@include theme.justify-content(space-between);
					}
				}
			}
		}

		&__bottomSection {
			padding: 10px 0 15px;

			form {
				margin-bottom: 15px;
			}
		}
	}

	&__dataWrapper__infoSection {
	
		@include theme.respond-to(900px) {
			margin-left: 40px;
		}

		
	}

	&__mobileInfoSection {
		display: none;
		width: stretch;
		position: fixed;
		bottom: 0;
		z-index: 9999;
		max-width: none;
		min-width: none;
		margin: 0;
		padding: 0 15px;

		section {
			max-width: 400px;
			margin: 0 auto;
		}

		&__amounts {

			&__button {
				margin: 5px auto;
				
				svg {
					transform: rotate(180deg);
				}

				&[is-expanded] {
					svg {
						transform: rotate(0);
					}
				}
			}
		}
	}

	@include theme.respond-to(map-get(theme.$breakpoints, 'screen-medium')) {
		
		&__dataWrapper__infoSection {
			display: none;
		}

		&__mobileInfoSection {
			display: block;
		}
	}


	&__renewalModal, &__picturesModal {

		svg {
			@include theme.font-size(5);
			@extend %text-color-grey;
			position: absolute;
			top: 20px;
			right: 20px;
			cursor: pointer;
		}
	}

	&__renewalModal {
		padding: 20px 50px;
		text-align: center;
		width: 460px;

		&__image {
			display: block;
			width: 50px;
			height: 50px;
			margin: 10px auto 15px;
			background-size: contain;
			background-position: center;
			background-repeat: no-repeat;
		}

		.radioWrapper {
			margin: 30px auto 20px;

			&__radioItem {
				background-color: transparent;
				&__text {
					text-align: left;
					margin-left: 15px;
					font-weight: 500;
				}
			}
			
		}

		&__buttonsWrapper {
			@include theme.flexbox-column-content-center;
			gap: 15px;

			button {
				width: 80%;
				margin: 20px 0 10px;
			}
		}
	}

	&__picturesModal {
		@include theme.flexbox-column-content-center;
		max-width: 400px;
		border-radius: 10px;
		padding: 20px 30px;

		&__picture {
			width: 100px;
			height: 100px;
			background-size: contain;
			background-position: center;
			background-repeat: no-repeat;
			margin: 0;
		}

		.text {
			margin: 5px 0;
		}
		
		&__limitTimeText {
			margin: 10px 0 !important;
		}

		&__button {
			margin: 25px 0 0;
		}
	}

	&__payingModal {
		padding: 25px 50px;
		text-align: center;

		svg {
			margin: 5px;
			width: 50px;
			height: 50px;
		}
	}

	@include theme.respond-to(map-get(theme.$breakpoints, 'screen-medium')) {
		@include theme.justify-content(flex-start);

		&__textSection {
			margin: 50px 20px 0;

			&__subtitle {
				margin: 20px 0 0;
			}
		}

		&__inputsSection {
			@include theme.flexbox-column-content-center;
			width: 80%;
			gap: 20px;
			margin: 40px 0 0;
	
			.inputWrapper {
				width: 100%;
				margin: 0;
			}
		}

		&__button {
			margin: 50px 0 0;
		}

		&__renewalModal {
			width: stretch;
			max-width: 400px;
			padding: 0 10px;

			.radioWrapper {
				margin: 10px auto;
			}
		}

		&__mobileInfoSection {

			&__bottomSection {
					
				&__digitalCoverText {
					display: block;
				}
			}
		}
	}

	@include theme.respond-to(map-get(theme.$breakpoints, 'screen-mobile')) {
		
		&__mobileCoverText {
			display: none;
			margin: 20px auto;
		}

		&__mobileInfoSection {

			&__bottomSection {
				
				button {
					padding: 10px 20px;
					width: 75%;
					@include theme.font-size(0);
				}
			}
		}
	}
}