@use '/src/styles/index.scss' as theme;

.logoWrapper {
  padding: 5px;
  display: inline-block;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;

  &__image {
    width: 130px;
    height: 30px;
    display: block;
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;

    &--full {
      width: 200px;
      height: auto;
    }
  }
}