/* Functional Component styles */
@use '/src/styles/index' as theme;

.backButton {
  @extend %text-color-text;
  padding: 14px;
  font-weight: 600;
  background-color: transparent;
  border: none;

  &[link-overlay] {
    position: absolute;
    z-index: 10;
  }

	&--justIcon {
		
		svg {
			@include theme.font-size(10);
		}
	}

  svg, span {
    display: inline-block;
    vertical-align: middle;
  }

  span {
    @include theme.font-size;
    margin-left: 5px;
  }

  &:hover {

    span {
      text-decoration: underline;
    }
  }

	@media only screen and (max-width: 600px) {

		&--hideTextOnMobile {
			
			span {
				display: none;
			}
		}
	}
}