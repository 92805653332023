/* Functional Component styles */
@use '/src/styles/index' as theme;  

.quoterHeader {


  width: 100%;
  position: fixed;
  top: 0;
  z-index: 10;

  &__content {
    position: relative;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
    width: 100%;
    @extend %bg-color-white;
    @include theme.flexbox-content-vertical-center;
    justify-content: space-between;

    &__logo {
      padding: 10px 15px;
    }

    &__helpButton {
      border: 2px solid #0D4EC9;
      padding: 5px 1rem;
      border-radius: 12px;
      margin: 1rem 2rem;
      &__link {
        @include theme.flexbox;
        align-items: center;
        gap: .5rem;    
        &__text {
          font-size: .7rem;
          @extend %text-color-main;
        }
      }
    }

    &__progress {

      position: absolute;
      width: 100%;
      height: 5px;
      z-index: 2;
      top: 100%;
      background-color: rgba(#000000, 1%);
  
      &__bar {
        width: 0;
        height: 100%;
        transition: width 500ms ease-in-out;
        @extend %bg-color-green;
  
      }
    }
  }

  &__helper {
    position: absolute;
    border-radius: 50%;
    top: 30%;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 3;
    border: 8px solid #fff;
    overflow: hidden;
    width: 80px;
    height: 80px;
    margin-bottom: 15px;
    @include theme.shadow;
    
    img {
      width: 100%;
      height: 100%;
      @extend %bg-color-yellow;
    }
  }

  @include theme.respond-to(620px) {

    .quoterHeader__content__helpButton {
      margin: 0rem 1rem;
     
    }

    &__helper {
      width: 70px;
      height: 70px;
      border-width: 6px;
    }
  }

  @include theme.respond-to(map-get(theme.$breakpoints, 'screen-medium')) {
    height: 70px;
    margin-bottom: 20px;

    &__content {
     
      &__logo {
        padding: 5px 10px;  

        img {
          width: 80px;
          height: 40px;
        }
      } 
    }


    &__helper {
      display: none;
    }

    &__progress {

      height: 4px;
    }
  }
}