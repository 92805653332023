@use 'src/styles/index' as theme;

html {
  overflow: overlay;
}

html, body {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  width: 100%;
  margin: 0;
}
@font-face {
  font-family: "Emoji";
  src: url('../public/AppleColorEmoji.ttf');
}
html, textarea, input {
  font-family: theme.$base-font-family, Emoji;
}

body {
  align-items: center;
}

input {
  outline: none;
}

button {

  all: unset;
  outline: none;
  -webkit-tap-highlight-color: transparent;
  text-align: center;

  &:hover:enabled {
    cursor: pointer;
  }
}

ul {
  padding: 0;
  margin: 0;

  li {
    list-style: none;
  }
}

a {
  text-decoration: none;

  &.underline:hover {
    text-decoration: underline;
  }
}

img {
  user-select: none;
}

p, h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

svg {
  display: inline-block;
  vertical-align: middle;
}

/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #d1d1d1;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: darkgrey;
}


// Structure selectors
#root {
  height: 100%;
  width: 100%;

  // @media only screen and (max-width: $mobileWidth) {
  //   min-height: 100%;
  // }
}

#main {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;

  & > div:last-of-type {
    width: 100%;
    //max-width: $maxWidth;
    margin: 0 auto;
  }
  
  // @media only screen and (max-width: $mobileWidth) {


  //   & > :last-child {
  //     padding-top: var(--header-height);
  //   }
  // }
}
