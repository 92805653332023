/* Functional Component styles */
@use '/src/styles/index' as theme;

.quoterLocation {
	height: 100%;

	&__form {
		@include theme.flexbox-column-content-center;
		width: 350px;
		gap: 20px;
		margin: 25px auto;

		&__inputWrapper {
			width: stretch;
		}
	}

	&__button {
		display: block;
		margin: 50px auto;
	}

	@include theme.respond-to(map-get(theme.$breakpoints, 'screen-mobile')) {
		&__button {
			padding: 10px 20px;
			width: 75%;
			@include theme.font-size(0);
		}
	}

	&__channel {

		padding: 20px 40px;

		&__title {
			margin-bottom: 20px;
			font-weight: bold;
		}

		&__button {
			margin-top: 30px;
			@include theme.flexbox-content-center;
		}

		@include theme.respond-to(map-get(theme.$breakpoints, 'screen-mobile')) {
			padding: 10px;

			&__button {
				margin-top: 20px;
			}
		}
	}
}