/* Functional Component styles */
@use '/src/styles/index' as theme;  

.quoterProfile {
	height: 100%;

	@include theme.respond-to(map-get(theme.$breakpoints, 'screen-medium')) {
		@include theme.justify-content(flex-start);

		&__button {
			margin: 50px 0 0;
		}
	}
}