/* Functional Component styles */
@use '/src/styles/index' as theme;  

.quoterVehicleId {
	height: 100%;

	&__form {
		@include theme.flexbox-column-content-center;

		&__vinLocation {
			@include theme.font-size(-2);
			@extend %text-color-main;
			visibility: hidden;
			margin-top: 20px;

			&--visible {
				visibility: visible;
			}

			@include theme.respond-to(map-get(theme.$breakpoints, 'screen-mobile')) {
				visibility: hidden;
			}
		}
	}

	&__vinLocationModal {
		text-align: center;
		padding: 20px 40px;
		margin: 0 auto;

		&__closeIcon {
			@include theme.font-size(8);
			position: absolute;
			top: 20px;
			right: 20px;
			cursor: pointer;
		}

		img {
			object-fit: contain;
			height: 300px;
			width: stretch;
    	max-width: 600px;
		}

		&__textWrapper {
			margin-bottom: 40px;

			&__firstParagraph {
				margin: 20px 0;
			}
		}

		@include theme.respond-to(1100px) {
			width: 85%;
			padding: 10px 20px;

			img {
				height: 200px;
				max-width: 350px;
			}
		}

		@include theme.respond-to(map-get(theme.$breakpoints, 'screen-mobile')) {
			width: 95%;
			padding: 10px;

			&__textWrapper {
				margin-bottom: 20px;
	
				&__firstParagraph {
					margin: 5px 0 10px;
				}
			}
		}
	}

	&__button {
		display: block;
		margin: 50px auto;
	}

	@include theme.respond-to(map-get(theme.$breakpoints, 'screen-medium')) {
		@include theme.justify-content(flex-start);
		&__textSection {
			margin: 50px 20px 0;

			&__subtitle {
				margin: 20px 0;
			}
		}
	}
}