/* Functional Component styles */
@use "/src/styles/index.scss" as theme;

.coverageOption {

  display: inline-flex;
  transition: 150ms;
  margin-top: 8px;
  position: relative;

  &:hover {
    cursor: pointer;

    &__selected {
      filter: brightness(.95);
    }
  }

  &__selected {
    @extend %bg-color-light-grey;
    @include theme.flexbox-content-vertical-center;
    @include theme.justify-content(space-between);
    @include theme.flex(1);
    border-radius: 15px;
    padding: 8px 12px;

    &__value {
      margin-right: 8px;
    }

    svg {
      width: 20px;
      height: 20px;
    }

    &[disabled-options] {
      opacity: .3;
      pointer-events: none;
    }
  }

  &__selected--open {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit;
  }

  &__list {
    @extend %bg-color-white;
    @include theme.bottom-shadow;
    position: absolute;
    z-index: 999;
    width: 100%;
    left: 0;
    top: 98%;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;

    &__item {
      margin: 1rem;

      &__value:hover {
        text-decoration: underline;
      }
    }
  }
}