@use '/src/styles/index' as theme;

.dropdownWrapper {
	position: relative;

	&--disabled {
		pointer-events: none;
		opacity: .6;
	}

	&--no-search {

		&:hover {
			cursor: pointer;
		}

		&__input {
			pointer-events: none
		}
	}

	&__input {
		padding: 20px 40px 10px 15px !important;
		text-overflow: ellipsis;

		&--shorter {
			padding: 20px 60px 10px 15px !important;
		}
	}

	&__arrowIcon {
		@include theme.font-size(12);
		@include theme.flexbox-content-vertical-center;
		@extend %text-color-grey;
		position: absolute;
		top: 0;
		height: 100%;
		right: 10px;
		transition: all 150ms;
		pointer-events: none;

		&--rotated {
			transform: rotate(180deg);
		}
	}

	&__deleteIcon {
		@include theme.flexbox-content-vertical-center;
		@extend %text-color-grey;
		@include theme.font-size(5);
		position: absolute;
		top: 0;
		right: 40px;
		height: 100%;

		svg {
			cursor: pointer;
		}
	}

	&__itemsList {
		@include theme.input-radius;
		@include theme.input-border;
		width: stretch;
		background-color: darken(#fff, 3%);
		position: absolute;
		max-height: 170px;
		overflow: auto;
		z-index: 2;

		&--top {
			bottom: 62px;
		}

		&--bottom {
			top: 62px;
		}

		&__item {
			@include theme.flexbox-content-between;
			@include theme.input-border(bottom);
			width: stretch;
			padding: 10px 20px;
			cursor: pointer;

			&__value {
				@include theme.font-size(-5);
			}

			&--current,
			&:hover {
				@extend %bg-color-light-grey;
			}

			&:last-child {
				border-bottom: none;
			}
		}

		&__notFound {
			padding: 25px 10px;
			text-align: center;

			p {
				margin: 0;
			}
		}
	}

	&__selection {

		&__loader {
			@extend %text-color-light-grey;

			svg {
				right: 40px;
				width: 35px;
				height: 35px;
				left: auto;
			}

			&--left {
				
				svg {
					right: 60px;
				}
			}
		}
	}
}