/* Functional Component styles */
@use '/src/styles/index.scss' as theme;

.personType {

  height: 100%;

  &__radios {
    width: 100%;
    max-width: 400px;
    margin: auto;
    padding: 0 10px;
    box-sizing: border-box;
  }

  &__invalid-image {
    @include theme.background-image(500px, 250px, block);
    margin: auto;

    @include theme.respond-to(500px) {
      @include theme.background-image(330px, 200px, block);
    }
  }

  &__buttons-wrapper {
    @include theme.flexbox-column-content-center;
    margin-top: 60px;

    &__link {
      margin-top: 30px;
    }
  }

  &__loader {
    @include theme.flexbox-column-content-center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 50px;
    height: 50px;

    svg {
      height: 35px;
      width: 35px;
    }
  }

	&__salvageMessage {
		padding: 20px;
    max-width: 600px;

		&__image {
			background-repeat: no-repeat;
			background-position: center;
			background-size: contain;
			display: block;
			width: 60px;
			height: 60px;
			margin: 0 auto 20px;
		}

		&__title {
			max-width: 350px;
			margin: 0 auto;
		}

		&__description {
			margin: 10px 0 0;
		}

    &__buttons{
			@include theme.flexbox-column-content-center;
      max-width: 500px;
      margin: 30px auto 0;

			&__button {
				margin: 0 0 20px;
			}
    }

    @include theme.respond-to(475px) {
      padding: 40px 5px 20px;

      &__item {
				max-width: 300px;
        margin: 40px auto;
      }

			&__buttons {

				&__button {
					padding: 10px 20px;
					width: 75%;
					@include theme.font-size(0);
				}
			}
    }
	}

	@include theme.respond-to(map-get(theme.$breakpoints, 'screen-mobile')) {

		&__buttons-wrapper {

			button {				
				padding: 10px 20px;
				width: 75%;
				@include theme.font-size(0);
			}
		}
	}
}