/* Functional Component styles */
@use '/src/styles/index.scss' as theme;

.planCost {

  height: 150px;
  width: 150px;
  border-radius: 50%;
  margin: 15px auto;
  position: relative;
  @include theme.flexbox-column-content-center;
  @extend %bg-color-main;

  &--animateWrapper {
    animation: 2s ease-in-out costWrapper;
    -webkit-animation: 2s ease-in-out costWrapper;
  }


  svg {
    width: 3rem;
    height: 3rem;
  }

  &__circleRing {
      border: 1px solid var(--color-light-main);
      border-radius: 50%;
      z-index: -1;
    div {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background-color: var(--color-main);
      opacity: .2;
    } 
  }

  &__circleRing--animate {
    animation: 1.5s ease aura;
    -webkit-animation: 1.5s ease aura;
  }
  
  &__discountText {
    position: absolute;
    top: 20px;
    text-decoration: line-through;
  }

  &__infoText {

    &--bottom {
      max-width: 100px;
      position: absolute;
      bottom: 10px;
      font-size: 10px !important;
      margin: 0;
    }
  }
  
  * {
    color: #fff;
  }

  &__costWrapper {

    height: 40px;
    overflow-y: hidden;
    overflow-x: hidden;
    @include theme.flexbox;
    align-items: baseline;

    &__total {
      display: none;
      position: absolute;
    }

    &__value {

      position: relative;

      &__slot {
        font-size: 28px;
        font-weight: 600;
        text-align: center;
        height: 40px;
      }
    }

    &__value--decimal {
      display: flex;
      align-self: flex-start;
      top: .35rem;
      animation: 200ms ease-out decimals;
      animation-fill-mode: forwards;
      animation-delay: 2.75s;
      opacity: 0;

      * {
        font-size: 1rem;
        font-weight: 400;
      }
    }

    &__value--number{
      animation: 2s ease-in-out roll;
      animation-fill-mode: forwards;
      width: 1.2rem;

      &[roll-to-hide] {
        animation: 2s ease-in-out rollAndHide;
        animation-fill-mode: forwards;
      }
    }

    &__value[hide-field] {
      display: none;
    }
  }

  /** KEYFRAMES ANIMATIONS **/
  // Roll numbers Animation
  &__costWrapper {

    $valueDelay: .5s;
    $count: 8;
    @while $count >= 1 {
  
      &__value--number:nth-of-type(#{$count}){
        animation-delay: $valueDelay;
      }
      $valueDelay: $valueDelay + .1s;
      $count: $count - 1;
    }
  }

  @keyframes decimals {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }


  @keyframes roll {
    0% { transform: translateY(0) }
    70% { top: 2.5rem; transform: translateY(-104%) }
    80% { top: 2.5rem;transform: translateY(-98%) }
    95% { top: 2.5rem;transform: translateY(-101%) }
    98% { top: 2.5rem;transform: translateY(-100.5%) }
    100% { top: 2.5rem;transform: translateY(-100%) }
  }
  @keyframes rollAndHide {
    0% { transform: translateY(0); opacity: 1 }
    70% { top: 2.5rem; transform: translateY(-104%) }
    80% { top: 2.5rem;transform: translateY(-98%); width: 0; opacity: 0; }
    95% { top: 2.5rem;transform: translateY(-101%); width: 0; opacity: 0;}
    98% { top: 2.5rem;transform: translateY(-100.5%); width: 0; opacity: 0;}
    100% { top: 2.5rem;transform: translateY(-100%); width: 0; opacity: 0;}
  }

  // Circle Animation
  @keyframes costWrapper {
    0% { transform: rotate(0deg) scale(1); }
    40% { transform: rotate(15deg) scale(.9); }
    80% { transform: rotate(-5deg) scale(1.1); }
    100% { transform: rotate(0deg) scale(1); }     
  }

  // Aura Animation
  $auraDelay: 1s;
  @for $i from 1 through 2 {

    &__circleRing--animate:nth-of-type(#{$i}){
      animation-delay: $auraDelay;
    }
    $auraDelay: $auraDelay + .5s;
  }

  @keyframes aura {
    0% { opacity: 1; transform: scale(1); }
    100% { opacity: 0; transform: scale(1.25); }     
  }
  /** END KEYFRAMES ANIMATION **/
}
