/* Functional Component styles */
.segment {
  $borderRadius: 30px;
  border-radius: $borderRadius;
  overflow: hidden;
  border: 1px solid #ccc;
  display: flex;
  position: relative;
  max-width: 450px;
  margin: 1.5rem auto 1rem;

  &__indicator {
    position: absolute;
    height: 100%;
    border-radius: $borderRadius;
    box-shadow: 0 0 10px var(--color-grey);
    z-index: 1;
    transition: 250ms ease-out;
    pointer-events: none;
  }

  a, &__button {
    width: 100%;
    height: 100%;
    padding: .6rem;
    background-color: #f5f5f5;
    user-select: none;
    font-size: .9rem;
    text-align: center;

    &[segment-active] {
      font-weight: bold;
    }
  }
}