/* Functional Component styles */
@use '/src/styles/index' as theme;  

.quoterCompletion {
	@include theme.flexbox-column-content-center;
	@include theme.justify-content(flex-start);
	width: 100%;
	height: 100%;

	&__textSection {
		margin: 0 0 50px;

		&__subtitle {
			margin: 5px 0 0 0;
		}
	}

	&__contentSection, &__mobileContentSection {
		@include theme.shadow;
		@include theme.flexbox-column-content-center;
		width: 90%;
		max-width: 500px;
		border-radius: 10px;
		padding: 20px 30px;

		&__picture {
			width: 100px;
			height: 100px;
			background-size: contain;
			background-position: center;
			background-repeat: no-repeat;
			margin: 0 0 20px;
		}

		.text {
			margin: 5px 0;
		}
		
		&__limitTimeText {
			margin: 10px 0 !important;
		}

		&__button {
			margin: 25px 0 0;
		}
	}

	&__mobileContentSection {
		display: none;
		width: stretch;
		max-width: none;
		padding: 20px;
	}

	@include theme.respond-to(map-get(theme.$breakpoints, 'screen-mobile')) {
		@include theme.justify-content(flex-start);

		&__textSection {
			margin: 30px 0 50px;

			&__subtitle {
				margin: 20px 0 0;
			}
		}

		&__button {
			margin: 50px 0 0;
		}
	}

	@include theme.respond-to(map-get(theme.$breakpoints, 'screen-medium')) {

		&__contentSection {
			display: none;
		}

		&__mobileContentSection {
			display: flex;
		}

		&__textSection {
			margin: 30px 0 50px;

			&__subtitle {
				margin: 20px 0 0;
			}
		}

		&__button {
			margin: 50px 0 0;
		}
	}
}