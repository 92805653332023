@use '/src/styles/index.scss' as theme;

.modalWrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 99998;
  top: 0;
  left: 0;
  @include theme.flexbox;
  @include theme.justify-content(center);

  &--center {
    @include theme.align-items(center);
  }

  &--top {
    padding-top: 20px;
    @include theme.align-items(flex-start);
  }

  &--bottom {
    padding-bottom: 20px;
    @include theme.align-items(flex-end);
  }

  &__background-overlay {
    width: 100%;
    height: 100%;
    z-index: 0;
    top: 0;
    left: 0;
    background-color: #afafaf80;
    // background-color: #afafaf4d;
    // backdrop-filter: blur(6px);
    position: absolute;
  }

  &__content {
    padding: 10px;
    border-radius: 12px;
    min-height: 50px;
    min-width: 100px;
    max-width: 700px;
    max-height: 95%;
    margin: 0 20px;
    transition: 100ms;
    box-sizing: border-box;
    position: relative;
    @include theme.flexbox;
    @include theme.shadow;
    @extend %bg-color-white;

    &--center, &--top {
      animation: 400ms ease modalTopIn;
      -webkit-animation: 400ms ease modalTopIn;
    }

    &--bottom {
      animation: 300ms ease modalBottomIn;
      -webkit-animation: 300ms ease modalBottomIn;
    }

    @include theme.respond-to(map-get(theme.$breakpoints, 'screen-mobile')) {
			margin: 0px;
      max-height: 100%;
      border-radius: 0;
    }
  }

  @keyframes modalTopIn {
    0% { 
      opacity: 0; 
      transform: translateY(-25%); 
    }
    100% { 
      opacity: 1;
      transform: translateY(0); 
    }     
  }

  @keyframes modalBottomIn {
    0% { 
      opacity: 0.2; 
      transform: translateY(25%); 
    }
    100% { 
      opacity: 1;
      transform: translateY(0); 
    }     
  }
}