/* Functional Component styles */
@use '/src/styles/index' as theme;

.inputDate {
	@include theme.input-border;
	@include theme.input-radius;
	@include theme.input-label-color;

	// cursor: pointer;
	position: relative;
	padding: 3px 0 0;
	cursor: pointer;

	input {
		cursor: pointer;
	}

	&__label {
		position: absolute;
		transform: translate(15px, 12px) scale(1);
		transform-origin: top left;
		transition: transform 150ms;
		

		pointer-events: none;

		span {
			
			@include theme.input-label-color;
			//@extend %text-color-text;
		}

		&--active {
			transform: translate(15px, -3px) scale(.75);
		}
	}

	&__selectedDate {
		@include theme.input-padding(18px, 20px, 10px, 15px);
		@include theme.input-radius;
		height: 22px;

		&__text {
			@include theme.font-size(-2);
			font-weight: 400;
		}
	}

	&__inputWrapper {
		@include theme.flexbox;
		@include theme.input-border(left);
		max-width: 110px;
		padding: 1px 0;

		&__input {
			@include theme.font-size(-3);
			@include theme.input-label-color;
			@include theme.input-color;
			width: 100%;
			border: none;
			text-align: center;
		}
	}

	&__calendarWrapper {
		position: absolute;
		top: 105%;
		left: 0;
    z-index: 99;
    box-shadow: 1px 2px 10px #ababab;
    border-radius: 5px;

		&.top {
			top: auto;
			bottom: 105%;
		}
	}

	&--focused {
		@include theme.input-focus-border-color;
		transition: 200ms border-color;
	}

	@include theme.respond-to(map-get(theme.$breakpoints, 'screen-mobile')) {
		padding: 0;

		&__label {
			@include theme.input-padding(2px, 15px, 2px, 2px);
		}

		&__inputWrapper {
			padding: 1px 0;

			&__input {
				@include theme.font-size(-3);
			}
		}
	}
	 
	&--invalid {
		border: 2px solid;
		@include  theme.input-border-error;
	}
}