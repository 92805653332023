/* Functional Component styles */
@use '/src/styles/index' as theme;

.inputsGroup {
	min-width: 400px;
	max-width: 400px;
	padding: 20px 40px;
	box-shadow: 0 0 10px -6px;
	border-radius: 12px;

	&__inputSection {

		&__title {
			@include theme.font-size(-1);
			margin: 0 0 15px;
		}
	
		& > .stepsBarWrapper {
			margin: 35px 0 30px;
	
			@include theme.respond-to(map-get(theme.$breakpoints, 'screen-mobile')) {
				margin: 25px 0 30px;
			}
		}



		&__hintButton {
			@include theme.font-size(-2);
			@extend %text-color-main;
			margin: 0 0 20px;
			display: none;
		}

		@include theme.respond-to(map-get(theme.$breakpoints, 'screen-mobile')) {
			
			&__hintButton {
				display: block;
			}

			&--hasHint {
				& > .stepsBarWrapper {
					margin: 25px 0 15px;
				}
			}
		}


	}


	&__buttonSection {
		@include theme.flexbox;
		@include theme.justify-content(center);

		&__backButton {
			height: 42px;

			&__button {
				@include theme.flexbox-content-center;
				@include theme.font-size(2);
				background-color: #f2f2f2;
				border-radius: 50%;
				width: 40px;
				height: 40px;
				color: #595C68;
				cursor: pointer;
			}
		}

		&--spaced {
			@include theme.justify-content(space-between);
		}
	}

	@include theme.respond-to(map-get(theme.$breakpoints, 'screen-mobile')) {
		min-width: none;
		max-width: none;
		padding: 20px 25px;
		border-radius: 15px;
		border-bottom-right-radius: 0px;
		border-bottom-left-radius: 0px;
		position: fixed;
		bottom: 0;
		width: stretch;
		min-width: unset;
		box-shadow: 0px -7px 5px -1px #cccccc30;
		margin-bottom: 30px;
		
		&__buttonSection {

			button {
				width: 85%;
				@include theme.font-size(0);
				padding: 10px 20px;
			}
		}
	}
}