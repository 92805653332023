/* Functional Component styles */
@use '/src/styles/index' as theme;  

.quoterPlanReview {
	height: 100%;

	&__dataWrapper {
		@include theme.flexbox;
		@include theme.align-items(flex-start);
		width: stretch;
		max-width: 1200px;
		margin: 0 20px;

		&__dataSection {
			@include theme.flexbox-column-content-center;
			@include theme.align-items(flex-start);
			gap: 40px;
			width: 800px;
			min-height: 100px;

			.dropdownWrapper, .inputWrapper, .inputCardWrapper, form {
				width: stretch;
			}
			
			&__information {
				@include theme.shadow;
				text-align: center;
				margin: 40px 0 0 0;
				padding: 30px 15px;
				border-radius: 10px;

				&__title {
					margin: 0 0 15px;
				}
	
				&__text {
					margin: 5px 0;
				}
			}
		}

		// &__infoSection {
		// 	@include theme.shadow;
		// 	min-width: 300px;
		// 	max-width: 300px;
		// 	padding: 0 15px;
		// 	margin: 0 0 0 30px;
		// 	border-radius: 10px;
		// 	text-align: center;

		// 	&__amounts {
		// 		padding: 10px 0;
		// 		border-bottom-width: 1px;
		// 		border-bottom-style: solid;
		// 		@extend %border-color-light-grey;

		// 		&__bigAmount {
		// 			@include theme.font-size(16);
		// 			letter-spacing: -2px;
		// 			line-height: 1;
		// 		}

		// 		&__subscriptionAmount {
		// 			margin-top: 10px;
		// 			letter-spacing: -2px;
		// 			line-height: 1;
		// 		}

		// 		&__monthly {

		// 			&__price {
		// 				line-height: 1;
		// 				margin: 5px 0 0;
		// 			}
		// 		}

		// 		&__button {
		// 			margin: 15px auto 10px;

		// 			svg { 
		// 				@include theme.font-size(7);
		// 				@extend %text-color-main;
		// 				margin: -3px 0 0 3px;
		// 				transition: all 100ms;
		// 			}

		// 			&[is-expanded] {

		// 				svg {
		// 					transform: rotate(180deg);
		// 				}
		// 			}
		// 		}
		// 	}

		// 	&__summary {
		// 		border-bottom-width: 1px;
		// 		border-bottom-style: solid;
		// 		@extend %border-color-light-grey;
				
		// 		&__title {
		// 			display: block;
		// 			margin: 15px 0 10px;
		// 		}

		// 		&__list {
		// 			margin-bottom: 10px;

		// 			&__listItem {
		// 				@include theme.flexbox;
		// 				@include theme.justify-content(space-between);
		// 				margin: 5px 0;
		// 			}

		// 			&__monthlyItem {
		// 				text-align: left;
						
		// 				&__amount {
		// 					width: stretch;
		// 					@include theme.flexbox;
		// 					@include theme.justify-content(space-between);
		// 				}
		// 			}
		// 		}
		// 	}

		// 	&__bottomSection {
		// 		padding: 10px 0 15px;

		// 		form {
		// 			margin-bottom: 15px;
		// 		}
		// 	}
		// }
	}

	&__mobileInfoSection, &__dataWrapper__infoSection {
		@include theme.shadow;
		@extend %bg-color-white;

		min-width: 300px;
		max-width: 300px;
		padding: 0 15px;
		margin: 0 0 0 30px;
		border-radius: 10px;
		text-align: center;

		&__amounts {
			padding: 10px 0;
			border-bottom-width: 1px;
			border-bottom-style: solid;
			@extend %border-color-light-grey;

			&__bigAmount {
				@include theme.font-size(16);
				letter-spacing: -2px;
				line-height: 1;
			}

			&__subscriptionAmount {
				margin-top: 10px;
				letter-spacing: -2px;
				line-height: 1;
			}

			&__monthly {

				&__price {
					line-height: 1;
					margin: 5px 0 0;
				}
			}

			&__button {
				margin: 15px auto 10px;

				svg { 
					@include theme.font-size(7);
					@extend %text-color-main;
					margin: -3px 0 0 3px;
					transition: all 100ms;
				}

				&[is-expanded] {

					svg {
						transform: rotate(180deg);
					}
				}
			}
		}

		&__summary {
			border-bottom-width: 1px;
			border-bottom-style: solid;
			@extend %border-color-light-grey;
			
			&__title {
				display: block;
				margin: 15px 0 10px;
			}

			&__list {
				margin-bottom: 10px;

				&__listItem {
					@include theme.flexbox;
					@include theme.justify-content(space-between);
					margin: 5px 0;
				}

				&__monthlyItem {
					text-align: left;
					
					&__amount {
						width: stretch;
						@include theme.flexbox;
						@include theme.justify-content(space-between);
					}
				}
			}
		}

		&__bottomSection {
			padding: 10px 0 15px;

			form {
				margin-bottom: 15px;
			}
		}
	}

	&__mobileInfoSection {
		display: none;
	}

	@include theme.respond-to(1200px) {

		&__dataWrapper {

			&__dataSection {
				width: stretch;
			}

			&__infoSection {
				min-width: 250px;
				max-width: 250px;
				margin: 0 0 0 15px;
			}
		}
	}

	@include theme.respond-to(map-get(theme.$breakpoints, 'screen-medium')) {
		@include theme.justify-content(flex-start);

		// .backButton {
		// 	left: 15px;
		// 	top: 60px;
		// }

		&__textSection {
			margin: 50px 20px 0;

			&__subtitle {
				margin: 20px 0 0;
			}
		}

		&__inputsSection {
			@include theme.flexbox-column-content-center;
			width: 80%;
			gap: 20px;
			margin: 40px 0 0;
	
			.inputWrapper {
				width: 100%;
				margin: 0;
			}
		}

		&__button {
			margin: 50px 0 0;
		}

		&__dataWrapper {

			&__dataSection {
				padding-bottom: 200px 
			}

			&__infoSection {
				display: none;
			}
		}

		&__mobileInfoSection {
			display: block;
			width: stretch;
			position: fixed;
			bottom: 0;
			z-index: 9999;
			max-width: none;
			min-width: none;
			margin: 0;
			padding: 0 15px;

			&__amounts {
				padding-bottom: 0;

				&__button {
					margin: 10px auto;
				}
			}

			section {
				max-width: 420px;
				margin: 0 auto;
			}
			// &__bottomSection {
			// 	padding-top: 20px;
			// }
		}
	}



	@include theme.respond-to(map-get(theme.$breakpoints, 'screen-mobile')) {

		&__dataWrapper {

			&__dataSection {
				padding-bottom: 220px;
			}
		}

		&__mobileInfoSection {

			&__bottomSection {

				button {
					padding: 10px 20px;
					width: 75%;
					@include theme.font-size(0);
				}
			}
		}
	}
}