/* Functional Component styles */
@use '/src/styles/index' as theme;

.inputCardWrapper {
	@include theme.input-border;
	@include theme.input-radius;
	@include theme.input-padding(20px, 18px, 18px, 18px);

	&[is-focused] {
		border-color: theme.$input-focus-border-color;
	}
}