// Border for inputs
@mixin input-border($side: all) {

	@if $side == 'top' {
		border-top: 2px solid $input-border-color;
	} 
	
	@else if $side == 'right' {
		border-right: 2px solid $input-border-color;
	} 
	
	@else if $side == 'bottom' {
		border-bottom: 2px solid $input-border-color;
	} 
	
	@else if $side == 'left' {
		border-left: 2px solid $input-border-color;
	} 
	
	@else {
		border: 2px solid $input-border-color;
	}
}

// Color for inputs borders
@mixin input-border-color {
	border-color: $input-border-color;
}

// Padding for inputs
@mixin input-padding($top: 15px, $right: 15px, $bottom: 15px, $left: 15px) {
	padding: $top $right $bottom $left;
}

// Border radius for inputs
@mixin input-radius($top: 7px, $right: 7px, $bottom: 7px, $left: 7px) {
	border-radius: $top $right $bottom $left;
}

// Text color for inputs
@mixin input-color {
	color: $-color-text;
}

// Width available for inputs
@mixin input-width-available {
	width: 100%;
	box-sizing: border-box;
}

// Color for inputs labels
@mixin input-label-color {
	color: $input-label-color;
}

@mixin input-border-error {
	border-color: $-color-red;
}

// Color for inputs borders on focus
@mixin input-focus-border-color {
	border-color: $input-focus-border-color;
}

// Color for inputs carets
@mixin input-caret-color {
	caret-color: $input-label-color;
}