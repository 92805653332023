/* Functional Component styles */
@use '/src/styles/index' as theme;  

.quoterPerson {
	height: 100%;

	&__form {
		@include theme.flexbox-column-content-center;
		width: 350px;
		gap: 20px;
		margin: 0 auto;
		
		&__inputWrapper {
			width: stretch;

			&--space-bottom {
				margin-bottom: 20px;
			}
		}
		
	}

	&__button {
		display: block;
		margin: 50px auto;
	}

	@include theme.respond-to(map-get(theme.$breakpoints, 'screen-medium')) {

		&__form {
			@include theme.flexbox-column-content-center;
			gap: 20px;
			margin-top: 20px;
	
			.inputWrapper {
				width: 100%;
				margin: 0;
			}
		}
	}
	
	@include theme.respond-to(map-get(theme.$breakpoints, 'screen-mobile')) {

		&__form {
			width: 85%
		}

		&__button {
			padding: 10px 20px;
			width: 75%;
			@include theme.font-size(0);
		}
	}
}