/* Functional Component styles */
@use '/src/styles/index' as theme;

.formError{
	@include theme.flexbox-content-center;
	@include theme.form-error-color;
	width: stretch;
	margin: 10px 0 0;

  svg {
    margin-right: 5px;
    width: 22px;
    height: 22px;
  }

  p {
		width: 90%;
    font-size: 0.8rem;
  }
}