/* Functional Component styles */
@use '/src/styles/index.scss' as theme;

.personAccount {

  &__password {
    @extend %bg-color-white;
    @include theme.shadow;
    border-radius: 15px;
    padding: 10px 20px;
    max-width: 500px;
    margin: auto;

		@include theme.respond-to(map-get(theme.$breakpoints, 'screen-mobile')) {
			display: none;
		}

    &__email {
      margin-bottom: 20px;

      &--title {
        margin-bottom: 0;
      }
    }

    &__input {
      
      margin-top: 15px;
      position: relative;

      &__current-rules {
        position: absolute;
        right: -235px;
        top: -10px;
        padding: 10px 15px;
        z-index: 5;
        animation: rules-in-left ease-out 300ms;

        &__bg {
          @extend %bg-color-white;
          @include theme.shadow;
          position: absolute;
          width: 100%;
          height: 100%;
          border-radius: 7px;
          z-index: -1;
          top: 0;
          left: 0;
        }

        &__arrow {
          position: absolute;
          width: 25px;
          height: 25px;
          transform: rotate(45deg);
          left: -12px;
          top: 25px;
          @extend %bg-color-white;

          &--shadow {

            @include theme.shadow;
            z-index: -3;
          }
        }

        &__item {

          @include theme.flexbox-content-vertical-center;

          &__icon {

            svg {
              width: 20px;
              height: 20px;
            }

            &--invalid {
              @extend %text-color-red;
            }

            &--valid {
              @extend %text-color-green;
            }
          }

          &__text {
            margin-top: 5px;
            margin-left: 5px;
          }
        }

        
        @keyframes rules-in-left {
          0% {
            opacity: .5;
            transform: translateX(20px);
          }

          100% {
            opacity: 1;
            transform: translateX(0px);
          }
        }

        @keyframes rules-in-top {
          0% {
            opacity: .5;
            transform: translateY(20px);
          }

          100% {
            opacity: 1;
            transform: translateY(0px);
          }
        }
      }

      @include theme.respond-to(1000px) {

        &__error {
          margin-left: 45px;
        }

        &__current-rules {
          right: inherit;
          left: -20px;
          top: 100%;
          animation-name: rules-in-top;
  
          &__arrow {
            left: 40px;
            top: -15px;
          }
        }
      }
    }

    &__rules {

      margin: 10px 0;

      ul {

        li {
          list-style: disc;
          margin-left: 20px;
        }
      }
    }

    &__button {
      @include theme.flexbox-content-center;
      margin: 25px 0;

			@include theme.respond-to(map-get(theme.$breakpoints, 'screen-mobile')) {
				button {
					padding: 10px 20px;
					width: 80%;
					@include theme.font-size(0);	
				}
			}
    }

		&--mobile {
			display: none;

			@include theme.respond-to(map-get(theme.$breakpoints, 'screen-mobile')) {
        display: block;
        padding-bottom: 80px;


				.personAccount__password__button{

          position: fixed;
          bottom: 0;
          z-index: 1;
          width: 100%;
          left: 0;
          margin: 0;
          padding: 20px 0;
          background-color: white;

					button {
						padding: 10px 20px;
						width: 75%;
						@include theme.font-size(0);
					}
				}
			}
		}
  }
}