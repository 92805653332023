/* Functional Component styles */
@use '/src/styles/index.scss' as theme;

.planCoupon {

  max-width: 300px;
  margin: 20px auto;
  border-radius: 5px;
  padding: 10px;
  border-width: 1px;
  border-style: solid;
  transition: 100ms;
  position: relative;
  @include theme.flexbox-content-vertical-center;
  @extend %bg-color-white;
  @extend %border-color-light-grey;

  svg {
    width: 20px;
    height: 20px;
  }

  &__field {
    margin-left: 1rem;
    @include theme.flex(1);

    &__input {
      @include theme.flexbox;

      input {
        border: none;
        font-weight: bold;
        margin-right: .5rem;

        &::placeholder {
          font-weight: 500;
          opacity: .5;
        }

				@include theme.respond-to(map-get(theme.$breakpoints, 'screen-mobile')) {
					width: 190px;
				}
      }

      &__button {
        font-weight: bold;
        @extend %text-color-main;

        &--remove {
          @extend %text-color-red;
        }
      }
    }
    
    &__label {
      opacity: .8;
    }

  }

  &:focus-within {
    @extend %border-color-main;

    & > svg {
      @extend %text-color-main;
    }

    .planCoupon__field__label {
      opacity: 1;
      @extend %text-color-main;
    }
  }

  &[disabled-form] {

    input {
      @extend %bg-color-light-grey;
      opacity: .8;
      pointer-events: none;
    }
  }

  &__error {
    position: absolute;
    bottom: -25px;

    &__wrapper {
      @include theme.flexbox-content-vertical-center;

      svg {
        @extend %text-color-red;
        width: 15px;
        height: 15px;
        margin-right: 5px;
      }
    }
  }
}