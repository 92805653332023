/* Functional Component styles */
@use '/src/styles/index' as theme;

.phoneWrapper {
	@include theme.input-border;
	@include theme.input-radius;
	@include theme.flexbox-content-center;
	position: relative;
	background-color: #fff;

	&__inputWrapper {
		@include theme.flexbox-content-between;
		flex: 1;

		&__country {
			@include theme.flexbox-content-center;
			@include theme.input-radius;
			padding: 0 10px;
			transition: 150ms;
	
			&__countryFlag {
				width: 30px;
				height: 20px;
			}

			svg {
				font-size: 1.2rem;
			}
	
			&:hover {
				cursor: pointer;
				background-color: #eee;
			}

			&__countryCode {
				@include theme.font-size(-2);
			}
		}

		.inputWrapper {
			flex: 1;

			input {
				padding-left: 0;
			}

			span {
				transform: translate(0px, 17px) scale(1);

				&.inputWrapper__label--active {
					transform: translate(0, 4px) scale(.75);
				}
			}
		}
	}

  & > span {
    margin-left: 5px;
    font-size: .9rem;
  }

  &__countries {
    position: absolute;
    width: 100%;
    left: 0;
    top: 110%;
    overflow-y: auto;
    max-height: 250px;
    z-index: 99;
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid #d1d1d1;
    padding: 0.5rem 0;

    &__searchCountry {
			@include theme.flexbox-content-center;
      margin: 0.5rem 1rem;
      border-bottom: 2px solid #ddd;

      svg {
        font-size: 1.2rem;
				color: #949494;
      }

      input {
        flex: 1;
        padding: 0.5rem;
				border: none;
      }

      &:focus-within {
        border-color: theme.$input-focus-border-color;
				transition: 200ms border-color;

        svg {
          color: map-get(theme.$colors, 'main');
        }
      }
    }

		&__countryItem {
			@include theme.flexbox-content-vertical-center;
			cursor: pointer;
			margin: 7px 0;
			user-select: none;

			span {
				display: flex;
			}

			&__countryFlag {
				width: 30px;
				height: 20px;
				margin: 0 10px;
			}
		}
  }

	&--focused {
		border-color: theme.$input-focus-border-color;
		transition: 200ms border-color;
	}

	&--invalid {
		@include theme.input-border-error;
	}

	@include theme.respond-to(map-get(theme.$breakpoints, 'screen-mobile')) {

		&__inputWrapper {

			&__country {
				width: auto;
				padding: 0 5px;
			}
		}
	}
}