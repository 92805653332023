/* Functional Component styles */
@use '/src/styles/index' as theme;

.planReviewCoverage {
	@extend %border-color-light-grey;
	width: stretch;
	border-bottom-width: 2px;
	border-bottom-style: solid;
	padding: 0 0 20px;
	text-align: center;

	&:last-of-type {
		border-bottom: none;
	}

	&__description {
		@include theme.flexbox;
	
		&__image {
			@extend %bg-color-light-grey;
			width: 80px;
			height: 80px;
			border-radius: 50%;
			background-size: contain;
			background-position: center;
			background-repeat: no-repeat;
		}

		&__text {
			margin-left: 30px;
			flex: 1;

			&__details {
				@extend %bg-color-light-grey;
				width: fit-content;
				border-radius: 15px;
				padding: 8px 20px;
			}

			&__list {
				margin-top: 25px;
				text-align: left;
	
				&__item {
					@include theme.flexbox;
					margin-bottom: 15px;
	
					span {
						align-items: baseline;
						svg {
							@extend %text-color-main;
							width: 25px;
							height: 25px;
							margin-top: -10px;
						}
					}
	
					&__value {
						margin-left: 8px;
					}
				}
			}
		}
	}

	&__button {
		@extend %text-color-main;
		margin: 20px auto 0;

		svg {
			@include theme.font-size(5);
			transition: 250ms;
		}

		&--rotated {

			svg {
				transform: rotate(180deg);
			}
		}
	}
}