/* Functional Component styles */
@use '/src/styles/index' as theme;

.radioWrapper {
	@include theme.flexbox-column-content-center;
	width: stretch;
	gap: 5px;

	&__radioItem {
		@include theme.flexbox-content-vertical-center;
		@include theme.input-padding(10px, 20px, 10px, 20px);
		border-radius: 8px;
		background-color: darken(#fff, 4%);
		margin: 5px 0;
		max-width: 420px;
		width: stretch;
		transition: background-color 100ms;
		cursor: pointer;

		&:hover {
			background-color: darken(#fff, 8%);
		}

		&__radio {
			@extend %bg-color-white;
			@extend %border-color-light-grey;
			position: relative;
			width: 20px;
			height: 20px;
			border-width: 2px;
			border-style: solid;
			border-radius: 50%;
			margin-right: 10px;

			&--active {

				&::after {
					@extend %bg-color-main;
					content: '';
					width: 14px;
					height: 14px;
					position: absolute;
					border-radius: 50%;
					top: 0;
					bottom: 0;
					left: 0;
					right: 0;
					margin: auto auto;
				}
			}
		}

		&__text {
			flex: 1;
			margin: 0 auto;
			padding-right: 24px;
		}
	}
}