/* Functional Component styles */
@use '/src/styles/index' as theme;

.plan {

  height: 100%;
  margin-bottom: 130px;
  position: relative;
  box-sizing: border-box;

  &__bg {
    position: absolute;
    z-index: -1;
    height: 80%;
    width: 100%;
    top: 18%;
    @include theme.flexbox-column;


    &__image {
      width: 100%;
      height: auto;
    }

    &__color {
      background-color: #f8f8f8;
      height: 100%;
      width: 100%;
    }

    @include theme.respond-to(550px) {
      &--image {
        top: calc(100vh / 1.5);
      }

      &--color {
        height: 76%;
      }
    }
  }

  &__section {

    box-sizing: border-box;

    &__scroll-indicator {
      $sizeButton: 60px;

      @include theme.respond-to(800px) {
        $sizeButton: 70px;
      }

      position: relative;
      margin-top: 40px;
      height: $sizeButton;
      width: 100%;

      &__pulse {
        position: absolute;
        width: $sizeButton;
        height: $sizeButton;
        border-radius: 50%;
        left: 0;
        right: 0;
        margin: auto;
        border-width: 10px;
        border-style: solid;
        @extend %border-color-main;
        bottom: -10px;
        animation: pulse 1s linear infinite 0.3s;
        z-index: 0;
      }

      button {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        border-radius: 50%;
        width: $sizeButton;
        height: $sizeButton;
        @include theme.shadow;
        @extend %bg-color-main;  
        animation: circle 1s linear infinite 0s;
        animation-play-state:running;
        animation-direction:normal;
        z-index: 1;
        transition: background-color 100ms;
  
        svg {
          width: $sizeButton - 10px;
          height: $sizeButton - 10px;
          position: absolute;
          left: 0;
          right: 0;
          top: 20px;
          bottom: 0;
          margin: auto;
          @extend %text-color-white;

          animation: arrow 1s linear infinite 0s;
          animation-direction:normal;
          animation-play-state:running;
        }

        &:hover {
          @extend %bg-color-light-main;
        }
      }

      @keyframes pulse {
        0% {transform: scale(0); opacity: 0;}
        8% {transform: scale(0); opacity: 0;}
        15% {transform: scale(0.1); opacity: 1;}
        30% {transform: scale(0.5); opacity: 1;}
        100% {opacity: 0; transform: scale(1.2);}
      }

      @keyframes arrow
        {
          0%   {bottom:0;}
          75% {bottom:50px;}
          100% {bottom:0;}
        }

      @keyframes circle
        {
          0%   {height:$sizeButton;}
          10% {height: $sizeButton;}
          50% {height: calc($sizeButton + 10px);}
          75% {height: calc($sizeButton + 20px);}
          90% {height: calc($sizeButton + 10px);}
          100% {height: $sizeButton;}
        }
    }

    &--relative {
      position: relative;
    }

    &--space-top {
      margin-top: 40px;
    }

    &--center-content {
      @include theme.flexbox-content-center;
    }

    &__action-wrapper {

      width: 400px;
      margin: 30px auto;

      @include theme.respond-to(450px) {
        width: 300px;
      }
    }

    &__plan-details {
      max-width: 1000px;
      margin: auto;

			&__actions {
				@extend %bg-color-light-grey;
				margin: 0 10px;
				border-radius: 15px;
				padding: 6px 12px;

				span {
					margin-right: 5px;
				}

				@include theme.respond-to(map-get(theme.$breakpoints, 'screen-medium')) {
					margin: 5px 0 0;
				}
			}
    }

		#planManagementButton {

			@include theme.respond-to(map-get(theme.$breakpoints, 'screen-mobile')) {
				@include theme.font-size(1);
				width: 75%;
    		height: 30px;
			}
		}
  }

  &__requirements {
    padding: 20px;
    max-width: 600px;

    &__item {
      @include theme.flexbox-column-content-center;
      @include theme.shadow;
      border-radius: 10px;
      padding: 30px 0;
      margin: 40px 20px;

      &__action {

        &__text {
          @extend %text-color-main;
        }
      }
    }

    &__buttons{
			@include theme.flexbox-column-content-center;
			flex-flow: column-reverse;
      max-width: 500px;
      margin: auto;
      margin-top: 50px;

			&__button {
				margin: 5px 0;
			}

      @include theme.respond-to(550px) {
        margin-top: 0;
      }
    }

    @include theme.respond-to(475px) {
      padding: 40px 5px 20px;

      &__item {
				max-width: 300px;
        margin: 40px auto;
      }

			&__buttons {

				&__button {
					padding: 10px 20px;
					width: 75%;
					@include theme.font-size(0);
				}
			}
    }
  }

  &__vin {
    text-align: center;
		padding: 20px 40px;
		margin: 0 auto;

		&__closeIcon {
			@include theme.font-size(8);
			position: absolute;
			top: 20px;
			right: 20px;
			cursor: pointer;
		}

		img {
			object-fit: contain;
			height: 300px;
			width: stretch;
    	max-width: 600px;
		}

		&__textWrapper {
			margin-bottom: 40px;

			&__firstParagraph {
				margin: 20px 0;
			}
		}

		@include theme.respond-to(1100px) {
			width: 85%;
			padding: 10px 20px;

			img {
				height: 200px;
				max-width: 350px;
			}
		}
  }

  &__loader {
    @include theme.flexbox-column-content-center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 50px;
    height: 50px;

    svg {
      height: 35px;
      width: 35px;
    }
  }
}