/* Functional Component styles */
@use '/src/styles//index.scss' as theme;

.spinner {
  margin: auto;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
  width: 25px;
  height: 25px;
  position: absolute;

  &--relative {
    position: relative;
  }

  &--color-main {
    @extend %text-color-main;
  }

  &--color-white {
    @extend %text-color-white;
  }
}