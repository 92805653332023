/* Functional Component styles */
@use '/src/styles/index' as theme;  

.quoterEmail {
	height: 100%;

	&__form {
		width: 350px;
		margin: 0 auto;
		margin-top: 20px;


		&__inputWrapper {
			margin-top: 25px;
		}
	}

	&__button {
		display: block;
		margin: 50px auto;
	}

	&__existingEmailModal {
		padding: 30px 50px;

		&__textWrapper {

			&__title {
				margin: 10px 0 20px;
			}
		}

		&__buttonWrapper {
			@include theme.flexbox-column-content-center;
			margin: 20px 0 0;

			&__closeButton {
				margin: 20px 0 0;
			}
		}
	}

	@include theme.respond-to(map-get(theme.$breakpoints, 'screen-mobile')) {
		@include theme.justify-content(flex-start);

			.inputWrapper {
				width: stretch;
				margin: 0;
			}
		

		&__existingEmailModal {
			padding: 10px 10px;
		}

		&__button {
			padding: 10px 20px;
			width: 75%;
			@include theme.font-size(0);
		}
	}
}