/* Functional Component styles */
@use '/src/styles/index' as theme;

.anchor {
  
  &--block {
    display: block;
  }

  &__text {
    @extend %text-color-main;
  }

  &:hover {
    @extend %text-underline-color-main;
    
  }
}